import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";

const MailingListModal = ({ show, handleClose, handleSignup }) => {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const isValidEmail = (email) => {
    const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
    return pattern.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      handleSignup(email).then(result => {
        if (result.success) {
          setSubscribed(true);
        } else {
          alert("Something went wrong, please try again!");
        }
      }).catch(error => {
        console.error('Error:', error);
      });
    } else {
      alert("Please enter a valid email address!");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered className="signup-modal">
      
      {!subscribed ? (
        <div>
          <Modal.Header closeButton className="modal-top">
            <Modal.Title>Join the mailing list for updates about new TripGPT features!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-bottom">
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Subscribe below:</Form.Label>
                <Form.Control 
                  type="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email" 
                />
              </Form.Group>

              <button className="signup-btn button-animation" type="submit">
                Subscribe ✈️
              </button>
            </Form>
          </Modal.Body>
        </div>
        ) : (
        <div>
          <Modal.Header closeButton className="modal-top">
            <Modal.Title>Success!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-bottom">
            <div className="success-subscribed">You've successfully subscribed!</div>
          </Modal.Body>
        </div>
      )}
    </Modal>
  );
};

export default MailingListModal;
