import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, Polyline, useLoadScript } from '@react-google-maps/api';
import { getCenter } from 'geolib';

const containerStyle = {
  width: '350px',
  height: '500px',
};

function TravelPlanMap({ apiKey, places }) {
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [center, setCenter] = useState({ lat: -34.397, lng: 150.644 });

  const mapRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  useEffect(() => {
    if (isLoaded) {
      setMarkers(places);
      setCenter(getMapCenter(places));
    }
  }, [isLoaded, places]);

  useEffect(() => {
    function fitMapToBounds() {
      if (mapRef.current && markers.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach((marker) => {
          bounds.extend({ lat: marker.lat, lng: marker.lng });
        });
        mapRef.current.fitBounds(bounds);
      }
    }
  
    fitMapToBounds();
  }, [markers]);

  function getMapCenter(places) {
    const locations = places.map((place) => ({
      latitude: place.lat,
      longitude: place.lng,
    }));
    const center = getCenter(locations);
    return { lat: center.latitude, lng: center.longitude };
  }

  function onMapLoad(map) {
    mapRef.current = map;
  }

  function createInfoWindowContent(place) {
    const rating = place.rating !== undefined ? `<div class="rating-container"><img src="/star.svg" alt="Star Icon" /><span>${place.rating}(${place.user_ratings_total})</span></div>` : '';
    const photo = place.photo ? `<div class="image-container"><img src="${place.photo}" alt="${place.place}" class="full-image" /></div>` : '';
  
    return `
      <div class="maps-card-container">
        ${photo}
        <div class="info-container">
          <div class="card-title" title="${place.place}">${place.place}</div>
          ${rating}
        </div>
        <a href="${place.url}" target="_blank" class="card-link">Open in Google Maps</a>
      </div>`;
  }

  function exportToGoogleMaps() {
    let url = 'https://www.google.com/maps/dir/';

    markers.forEach((place) => {
      url += `${place.lat},${place.lng}/`;
    });

    window.open(url, '_blank');
  }

  if (loadError) {
    return <div>Error loading map</div>;
  }

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <div className="travel-map-container">
      <div className="travel-map">
        <GoogleMap
          ref={mapRef}
          onLoad={onMapLoad}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
        >
          {markers.map((marker, index) => (
            <Marker
              key={marker.place_id}
              position={{ lat: marker.lat, lng: marker.lng }}
              label={`${index + 1}`}
              onClick={() => setSelectedMarker(marker)}
            />
          ))}
          <Polyline
            path={markers.map((marker) => ({ lat: marker.lat, lng: marker.lng }))}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              icons: [
                {
                  icon: {
                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    fillColor: '#FF0000',
                    fillOpacity: 0.8,
                    strokeWeight: 1,
                    scale: 3,
                  },
                  offset: '50%',
                  repeat: '100px',
                },
              ],
            }}
          />
          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div dangerouslySetInnerHTML={{ __html: createInfoWindowContent(selectedMarker) }} />
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
      <div className="export-google-maps-container">
        <button className="export-google-maps" onClick={exportToGoogleMaps}>Open these directions in Google Maps</button>
      </div>
    </div>
  );
}

export default React.memo(TravelPlanMap);
