
import React, { useRef } from 'react';
import { Card, Image } from 'react-bootstrap';




function SampleItineraries() {
    const containerRef = useRef(null);

    const scrollLeft = () => {
        const container = containerRef.current;
        container.scroll({
          left: container.scrollLeft - 200,
          behavior: "smooth",
        });
    };
      
    const scrollRight = () => {
        const container = containerRef.current;
        container.scroll({
            left: container.scrollLeft + 200,
            behavior: "smooth",
        });
    };

    return (
        <div className="outer-container">
            <div className="arrow left-arrow" onClick={scrollLeft}>
                <img src="/arrow-left.svg" alt="Arrow left" />
            </div>
                
                {/* 
                
                SF: https://lh3.googleusercontent.com/places/ANJU3DuavhSa3TYxcOuEGwdwHsP0M7leBRvITTBKGQ0u09xUAaV0RIW3I7BIvOglVRFIscUuNT5a2uIZ24dpngtyOsB4INO5wT6j86g=s1600-w400
                Thailand: https://lh3.googleusercontent.com/places/ANJU3DtREqXoyZ6cNgipS7Ih41_7T6TwLlCaKzPN7Hsvf6ICv1x2RNtViCWPsHlrx6N81U5n1fWxgB6xeopLoJKrVoH7NAciHQ56CH4=s1600-w400
                Paris: https://lh3.googleusercontent.com/places/ANJU3Dvkvp7ZDs2Jpe1L87P4JNlWCDOfLdsCvQaCdJl9cxAItq0ZXFKEAZB98PAf2RgodsfK1dqCNYNAjIKYEhFLhlaDGH-pAhmXpCY=s1600-w400
                Italy: https://lh3.googleusercontent.com/places/ANJU3DtyxY1ch2_vwyGOrbLHoGtQnNhW6zHxRzVdrZ7k4MlP43_87cwdi1NBwOO-IoGvYMZaSgtBzUaDx0iJJZkp_WttD0W4NMXIgJo=s1600-w400
                Japan: https://lh3.googleusercontent.com/places/ANJU3DuYjRy4cqYCo226E1Fa8ZK7SYBQ-er34iD_BGZGGeMypB8rAodOSnSpKBMfDxYSwpLkZFHNUiEnQrgtAH6oeBhlc7L9LjQYCZ8=s1600-w400
                Cancun: https://lh3.googleusercontent.com/places/ANJU3DvlE4oDgObHPnzcyAoBzwRobNLynZWOqIhA1hYRewO4yf3TR60BOfrCAD9WBqDqQaaRzJf00YsdExgWLVHLIHPDb3HV6yTlmF4=s1600-w400
                Disney World: https://lh3.googleusercontent.com/places/ANJU3Dt34IEce9odFASQ9YP0vePLPb74-V_b3u-XMQh1yFpdFV_ppRdmGqVjhNI91dYoOLwOsdhjFKobn1vLaGMRdi3H016tb24gDXc=s1600-w400
                
                */}
                
            <div className="scroll-wrapper" ref={containerRef}>
                <div className="cards-container" >
                    <div className="card-wrapper">
                        
                        <a
                        href="/?itineraryId=8555ce63-6031-42f5-813f-842f1281729d"
                        className="card-link"
                        >
                            <Card
                                className="card-container button-animation"
                                key="1"
                            >
                                <div className="image-container">
                                <Image src="https://lh3.googleusercontent.com/places/ANJU3DuavhSa3TYxcOuEGwdwHsP0M7leBRvITTBKGQ0u09xUAaV0RIW3I7BIvOglVRFIscUuNT5a2uIZ24dpngtyOsB4INO5wT6j86g=s1600-w400" alt="" className="full-image" />
                                </div>
                                <div className="info-container">
                                <div className="card-title" title="California">California - 14 Days</div>
                                    <div className="rating-container">
                                    <span>
                                    Road Trip Through National Parks
                                    </span>
                                    </div>
                                </div>
                            </Card>
                        </a>
                        
                    </div>
                    <div className="card-wrapper">
                        <a
                        href="/?itineraryId=a2e1ee20-42d3-489b-b987-ddb2024eab60"
                        className="card-link"
                        >
                            <Card
                                className="card-container button-animation"
                                key="1"
                            >
                                <div className="image-container">
                                <Image src="https://lh3.googleusercontent.com/places/ANJU3DtREqXoyZ6cNgipS7Ih41_7T6TwLlCaKzPN7Hsvf6ICv1x2RNtViCWPsHlrx6N81U5n1fWxgB6xeopLoJKrVoH7NAciHQ56CH4=s1600-w400" alt="" className="full-image" />
                                </div>
                                <div className="info-container">
                                <div className="card-title" title="Thailand and Vietnam">Thailand and Vietnam - 14 Days</div>
                                    <div className="rating-container">
                                    <span>
                                    Backpacking Trip
                                    </span>
                                    </div>
                                </div>
                            </Card>
                        </a>
                    </div>
                    <div className="card-wrapper">
                        <a
                        href="/?itineraryId=1f76b240-1b8b-42c3-aa29-1eab1148cb10"
                        className="card-link"
                        >
                            <Card
                                className="card-container button-animation"
                                key="1"
                            >
                                <div className="image-container">
                                <Image src="https://lh3.googleusercontent.com/places/ANJU3Dvkvp7ZDs2Jpe1L87P4JNlWCDOfLdsCvQaCdJl9cxAItq0ZXFKEAZB98PAf2RgodsfK1dqCNYNAjIKYEhFLhlaDGH-pAhmXpCY=s1600-w400" alt="" className="full-image" />
                                </div>
                                <div className="info-container">
                                <div className="card-title" title="France">South of France - 14 Days</div>
                                    <div className="rating-container">
                                    <span>
                                    Romantic Honeymoon
                                    </span>
                                    </div>
                                </div>
                            </Card>
                        </a>
                    </div>
                    <div className="card-wrapper">
                        <a
                        href="/?itineraryId=359aa726-18a6-4b64-837c-b908d29a658e"
                        className="card-link"
                        >
                            <Card
                                className="card-container button-animation"
                                key="1"
                            >
                                <div className="image-container">
                                <Image src="https://lh3.googleusercontent.com/places/ANJU3DtyxY1ch2_vwyGOrbLHoGtQnNhW6zHxRzVdrZ7k4MlP43_87cwdi1NBwOO-IoGvYMZaSgtBzUaDx0iJJZkp_WttD0W4NMXIgJo=s1600-w400" alt="" className="full-image" />
                                </div>
                                <div className="info-container">
                                <div className="card-title" title="Italy">Italy - 10 Days</div>
                                    <div className="rating-container">
                                    <span>
                                    Historical and Culinary Excursion
                                    </span>
                                    </div>
                                </div>
                            </Card>
                        </a>
                    </div>
                    <div className="card-wrapper">
                        <a
                        href="/?itineraryId=8d77cbca-3b75-4b8f-98b0-63bf7765fb8e"
                        className="card-link"
                        >
                            <Card
                                className="card-container button-animation"
                                key="1"
                            >
                                <div className="image-container">
                                <Image src="https://lh3.googleusercontent.com/places/ANJU3DuYjRy4cqYCo226E1Fa8ZK7SYBQ-er34iD_BGZGGeMypB8rAodOSnSpKBMfDxYSwpLkZFHNUiEnQrgtAH6oeBhlc7L9LjQYCZ8=s1600-w400" alt="" className="full-image" />
                                </div>
                                <div className="info-container">
                                <div className="card-title" title="Japan">Japan - 11 Days</div>
                                    <div className="rating-container">
                                    <span>
                                    Culture and Traditional Exploration
                                    </span>
                                    </div>
                                </div>
                            </Card>
                        </a>
                    </div>
                    <div className="card-wrapper">
                        <a
                        href="/?itineraryId=8d77cbca-3b75-4b8f-98b0-63bf7765fb8e"
                        className="card-link"
                        >
                            <Card
                                className="card-container button-animation"
                                key="1"
                            >
                                <div className="image-container">
                                <Image src="https://lh3.googleusercontent.com/places/ANJU3DvlE4oDgObHPnzcyAoBzwRobNLynZWOqIhA1hYRewO4yf3TR60BOfrCAD9WBqDqQaaRzJf00YsdExgWLVHLIHPDb3HV6yTlmF4=s1600-w400" alt="" className="full-image" />
                                </div>
                                <div className="info-container">
                                <div className="card-title" title="Cancun">Cancun - 7 Days</div>
                                    <div className="rating-container">
                                    <span>
                                    Beach and Ancient History Getaway
                                    </span>
                                    </div>
                                </div>
                            </Card>
                        </a>
                    </div>
                    <div className="card-wrapper">
                        <a
                        href="/?itineraryId=8d77cbca-3b75-4b8f-98b0-63bf7765fb8e"
                        className="card-link"
                        >
                            <Card
                                className="card-container button-animation"
                                key="1"
                            >
                                <div className="image-container">
                                <Image src="https://lh3.googleusercontent.com/places/ANJU3Dt34IEce9odFASQ9YP0vePLPb74-V_b3u-XMQh1yFpdFV_ppRdmGqVjhNI91dYoOLwOsdhjFKobn1vLaGMRdi3H016tb24gDXc=s1600-w400" alt="" className="full-image" />
                                </div>
                                <div className="info-container">
                                <div className="card-title" title="Italy">Disney World, Orlando - 5 Days</div>
                                    <div className="rating-container">
                                    <span>
                                    Family Fun for 5 Days
                                    </span>
                                    </div>
                                </div>
                            </Card>
                        </a>
                    </div>
                </div>
            </div>
            <div className="arrow right-arrow" onClick={scrollRight}>
                <img src="/arrow-right.svg" alt="Arrow right" />
            </div>
        </div>
    )
};

export default SampleItineraries;