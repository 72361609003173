import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';



const ShareModal = ({ show, isSaved, itineraryId, handleClose, handleSignup }) => {
  const [email, setEmail] = useState("");
  const [copied, setCopied] = useState(false);

  const isValidEmail = (email) => {
    const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
    return pattern.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      handleSignup(email);
    } else {
      alert("Please enter a valid email address!");
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const url = `https://tripgpt.app/?itineraryId=${itineraryId}`;

  return (
    <Modal show={show} onHide={handleClose} centered className="signup-modal">
      
      {!isSaved ? (
        <div>
          <Modal.Header closeButton className="modal-top">
            <Modal.Title>To save your itinerary, please provide your email and we'll send you a link! This will also add you to our mailing list.</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-bottom">
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Subscribe below:</Form.Label>
                <Form.Control 
                  type="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email" 
                />
              </Form.Group>

              <button className="signup-btn button-animation" type="submit">
                Save ✈️
              </button>
            </Form>
          </Modal.Body>
        </div>
        ) : (
        <div>
          <Modal.Header closeButton className="modal-top">
            <Modal.Title>Share this itinerary</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-bottom">
            <div className="social-links">
              <FacebookShareButton url={url}>
                <FacebookIcon size={48} round={true} />
              </FacebookShareButton>

              <LinkedinShareButton url={url}>
                <LinkedinIcon size={48} round={true} />
              </LinkedinShareButton>

              <TwitterShareButton url={url}>
                <TwitterIcon size={48} round={true} />
              </TwitterShareButton>

              <EmailShareButton url={url}>
                <EmailIcon size={48} round />
              </EmailShareButton>
            </div>

            <button onClick={handleCopy} className="copy-link-button button-animation">
              {copied ? 'Copied!' : 'Copy link'}
            </button>
          </Modal.Body>
        </div>
      )}
    </Modal>
  );
};

export default ShareModal;
